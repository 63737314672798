const NotificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_SUCCESS_MESSAGE":
      return {
        ...state,
        successMessage: action.payload,
      };
    case "CLEAR_SUCCESS_MESSAGE":
      return {
        ...state,
        successMessage: null,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: true,
        },
      };
    case "FINISH_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: false,
        },
      };
    default:
      return state;
  }
};

export default NotificationReducer;
