import Link from "next/link";
import styled from "styled-components";

const StyledLink = styled.a`
  color: inherit;

  &:link,
  &:visited {
    text-decoration: none;
  }
`;

const CustomLink = (props) => {
  const { href, as, children } = props;

  return (
    <Link as={as ? as : href} href={href} passHref>
      <StyledLink>{children}</StyledLink>
    </Link>
  );
};

export default CustomLink;
