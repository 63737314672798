import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import App from "next/app";
import Head from "next/head";
import "css/antd.less";
import "react-quill/dist/quill.snow.css";
import { ThemeProvider } from "styled-components";
import { ReactQueryDevtools } from "react-query-devtools";

import theme from "lib/theme";
import DefaultLayout from "components/layouts/default/Default";
import { LoginProvider } from "context/Login/LoginState";
import { NotificationProvider } from "context/UI/Notification/NotificationState";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        event.exception.values[0].value !== "ResizeObserver loop limit exceeded"
      ) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

class MyApp extends App {
  render() {
    const { Component, pageProps, err } = this.props;
    const getLayout =
      Component.getLayout || ((page) => <DefaultLayout children={page} />);

    return (
      <>
        <Head>
          <title>Tihani Management System</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <ThemeProvider theme={theme}>
          <LoginProvider>
            <NotificationProvider>
              {getLayout(<Component {...pageProps} err={err} />)}
            </NotificationProvider>
          </LoginProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </>
    );
  }
}

export default MyApp;
