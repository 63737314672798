import { createContext, useReducer } from "react";
import NotificationReducer from "./NotificationReducer";

// Initial state
const initialState = {
  loading: false,
  errors: null,
  successMessage: null,
  loading: {
    addStaff: false,
    addClient: false,
  },
};

// Context
export const NotificationStateContext = createContext(initialState);

// Provider component
export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationReducer, initialState);

  // Actions
  function setSuccessMessage(message) {
    dispatch({
      type: "SET_SUCCESS_MESSAGE",
      payload: message,
    });
  }

  function clearSuccessMessage() {
    dispatch({
      type: "CLEAR_SUCCESS_MESSAGE",
    });
  }

  function setLoading(resource) {
    dispatch({
      type: "SET_LOADING",
      payload: resource,
    });
  }

  function finishLoading(resource) {
    dispatch({
      type: "FINISH_LOADING",
      payload: resource,
    });
  }

  return (
    <NotificationStateContext.Provider
      value={{
        state,
        setSuccessMessage,
        clearSuccessMessage,
        setLoading,
        finishLoading,
      }}
    >
      {children}
    </NotificationStateContext.Provider>
  );
};
